@import 'variables/variables';

.white-dot-button {
  button {
    svg {
      path {
        fill: $white;
      }
    }
  }
}

.tippy-box[data-theme~='dot-menu'] {
  background-color: $white;
  border-radius: $border-radius;
  border: 1px solid $blue-20;
  box-sizing: border-box;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: calculate-rem(5px) calculate-rem(20px);
}

.tippy-box[data-theme~='hint'] {
  font-family: $font-family-primary;
  max-width: 200px;
  background: $black-80;
  border-radius: 4px;
  font-style: normal;
  font-weight: $base-font-weight;
  font-size: calculate-rem(13px);
  line-height: calculate-rem(18px);
  color: $white;
}
