@import 'variables/variables';
@import 'functions/transitions.functions';
@import 'variables/text-field.variables';

$clear-icon-size: 30px;

.whc-form-field {
  height: $text-field-height;
  width: 100%;
  padding: 0 $text-field-padding;
  border-radius: $border-radius;
  border: 1px solid $blue-40;
  caret-color: $vivid-blue-100;
  transition: apply-input-field-focus-animation(box-shadow);
  font-size: calculate-rem(13px);
  font-weight: 600;
  display: flex;
  align-items: center;
  background: $white;

  &:hover {
    border: 1px solid $blue-70;
  }

  &:focus-within {
    border-color: $vivid-blue-100;
    box-shadow: $text-field-focus-shadow;
    outline: none;
  }

  input,
  textarea {
    border: none;
    width: 100%;
    outline: 0;
    font-weight: inherit;
    font-size: inherit;
    color: $black-100;
    background: transparent;

    &:disabled {
      background: $blue-3;
    }
  }

  span {
    width: $clear-icon-size;
  }
}

.has-icon {
  input {
    width: calc(100% - #{$clear-icon-size});
  }
}

.field-disabled {
  background: $blue-3;

  &:hover {
    border: 1px solid $blue-40;
  }
}
