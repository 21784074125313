@import 'variables/filter.variables';
@import 'functions/table.functions';

.filter-container {
  padding: $filter-container-padding;
  min-width: $filter-container-min-width;

  .filter-container-header {
    height: $app-header-height;
    display: flex;
    justify-content: space-between;
    margin-top: -$filter-container-padding;
    align-items: center;
    min-width: $filter-container-min-width;
  }

  .filter-cards-wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: $table-row-gap;
    margin-top: calculate-rem(11px);
    min-width: $filter-container-min-width;
  }
}

.filter-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: $filter-tag-max-width;
}

.filter-tag-container {
  height: $filter-tag-height;
  background-color: $filter-tag-color;
  border-radius: $filter-tag-border-radius;
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 0 $filter-tag-padding;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $filter-tag-max-width;
  margin: $filter-tag-margin-top $filter-tag-margin-right 0 0;
}

.filter-small-image {
  width: $filter-small-image-w-h;
  height: $filter-small-image-w-h;
  border-radius: $filter-small-image-border-radius;
  margin-right: $filter-small-image-margin-right;
}

.card-filtering-container {
  background-color: $white;
  border-radius: $border-radius;
  padding: $card-filtering-container-padding;
}

.my-filter-button {
  display: flex;
  color: white;
}

.delete-text {
  cursor: pointer;
}
