.partner-table {
  // each value defines a column in the table
  grid-template-columns:
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 2fr)
    50px;
}

.projects-table {
  grid-template-columns:
    minmax(150px, 2fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 2fr)
    50px;
}

.project-position-table {
  grid-template-columns:
    1fr
    1fr
    1fr
    1fr
    50px;
}

.providers-table {
  grid-template-columns:
    1fr
    1.5fr
    1fr
    1fr
    50px;
}

.candidate-position-table {
  grid-template-columns:
    minmax(0, 1fr)
    minmax(0, 1fr)
    minmax(0, 1fr)
    minmax(0, 1fr)
    minmax(0, 1fr)
    minmax(200px, 1fr)
    50px;
}

.users-table {
  grid-template-columns:
    minmax(0, 1fr)
    minmax(140px, 1fr)
    minmax(0, 1fr)
    minmax(0, 1fr)
    minmax(0, 1fr)
    50px;
}
