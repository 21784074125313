$base-font-size: 16px;
$base-font-weight: 600;
$font-family-primary: 'Open Sans', sans-serif;
$font-family-secondary: 'Neo Sans Pro', sans-serif;

@import '~theme/functions';
@import 'colors';
@import 'side-navigation.variables';

$app-header-height: calculate-rem(60px);
$app-header-mobile-height: calculate-rem(60px);

$app-filter-header-height: calculate-rem(50px);

$app-side-navigation-width: calculate-rem(60px);

$app-side-filter-width: calculate-rem(260px);

$app-bulk-edit-bar-height: calculate-rem(70px);

$sticky-bar-width: calculate-rem(0);

$auth-container-card-width: calculate-rem(460px);
$auth-container-padding-small: 7%;

$border-radius: 4px;

$sum-icon-size: 14px;
