.custom-date-picker {
  max-width: 260px;

  .mat-calendar-body-selected {
    background: $blue-100;
  }

  .mat-calendar-body-cell:hover {
    .mat-calendar-body-cell-content {
      background-color: $blue-40 !important;
    }
  }
}
