@import 'variables/variables';

.custom-switch-toggle {
  .switch {
    background: $blue-30;
    border: none;

    &.checked {
      background: $green-success-100;

      small {
        right: 3px;
      }
    }

    &.switch-small small {
      width: 15px;
      height: 15px;
    }

    &:not(.checked) {
      small {
        right: calc(100% - 18px);
      }
    }

    small {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
