.checkbox-label {
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 16px;
  width: 16px;
  clear: both;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &.blue {
    .checkbox-custom {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: transparent;
      border-radius: 2px;
      border: 2px solid $blue-100;
      &:hover {
        border: 2px solid $vivid-blue-100;
        background-color: $blue-3;
      }
    }

    .checkbox-custom::after {
      position: absolute;
      content: '';
      left: 12px;
      top: 12px;
      height: 0;
      width: 0;
      border-radius: 2px;
      border: solid $checkbox-border-color;
      border-width: 0 3px 3px 0;
      transform: rotate(0deg) scale(0);
      opacity: 1;
    }

    input:checked ~ .checkbox-custom {
      background-color: $vivid-blue-100;
      border-radius: 2px;
      transform: rotate(0deg) scale(1);
      opacity: 1;
      border: 2px solid $vivid-blue-100;
    }

    input:checked ~ .checkbox-custom::after {
      transform: rotate(45deg) scale(1);
      opacity: 1;
      left: 3px;
      top: -0.5px;
      width: 6.5px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      background-color: transparent;
      border-radius: 0.5px;
    }
  }

  &.red {
    .checkbox-custom {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: transparent;
      border-radius: 2px;
      border: 2px solid $blue-60;
    }

    .checkbox-custom::after {
      position: absolute;
      content: '';
      left: 12px;
      top: 12px;
      height: 0;
      width: 0;
      border-radius: 2px;
      border: solid $checkbox-border-color;
      border-width: 0 3px 3px 0;
      transform: rotate(0deg) scale(0);
      opacity: 1;
    }

    input:checked ~ .checkbox-custom {
      background-color: $vivid-red-100;
      border-radius: 2px;
      transform: rotate(0deg) scale(1);
      opacity: 1;
      border: 2px solid $vivid-red-100;
    }

    input:checked ~ .checkbox-custom::after {
      transform: rotate(45deg) scale(1);
      opacity: 1;
      left: 3px;
      top: -0.5px;
      width: 6.5px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      background-color: transparent;
      border-radius: 0.5px;
    }
  }
}
