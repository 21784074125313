@import 'variables/button.variables';

.header-button {
  height: $basic-primary-button-height !important;
  font-weight: bold !important;

  .primary-button-text-value {
    padding: 0 calculate-rem(20px);
    font-size: calculate-rem(13px);
  }
}

.load-more {
  button {
    font-family: $font-family-primary;
    font-style: normal;
    font-weight: 600;
    font-size: calculate-rem(13px);
    line-height: calculate-rem(18px);
    color: $vivid-blue-100;
    background: transparent;
    border: 0;
  }
}
