@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../fonts/open-sans-v18-latin-ext-regular.woff2') format('woff2'),
    url('../fonts/open-sans-v18-latin-ext-regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('../fonts/open-sans-v18-latin-ext-600.woff2') format('woff2'),
    url('../fonts/open-sans-v18-latin-ext-600.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../fonts/open-sans-v18-latin-ext-700.woff2') format('woff2'),
    url('../fonts/open-sans-v18-latin-ext-700.woff') format('woff');
}

@font-face {
  font-family: 'Neo Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../fonts/NeoSansProRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'Neo Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('../fonts/NeoSansProMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'Neo Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../fonts/NeoSansProBold.ttf') format('truetype');
}
