// color palette defined in UI kit

$white: #fff;
$light-white: #ffffff9c;

$black-100: #232323;
$black-80: #4f4f4f;
$black-60: #7b7b7b;

$light-blue-100: #005a93;
$light-blue-80: #337ba9;
$blue-100: #054265;
$blue-80: #376884;
$blue-70: #507b93;
$blue-60: #698ea3;
$blue-50: #82a0b2;
$blue-40: #9bb3c1;
$blue-30: #b4c6d1;
$blue-20: #cdd9e0;
$blue-12: #e1e8ed;
$blue-6: #f0f4f6;
$blue-3: #f7f9fb;

$vivid-green-100: #c1d959;
$vivid-red-100: #d41f26;
$vivid-blue-100: #058bdc;
$vivid-blue-20: #cde8f8;

$dark-red-100: #ae1e22;

$green-success-100: #22bc44;

$red-100: #ae1e22;
$vivid-red-100: #d41f26;

$checkbox-border-color: #009bff;

$primary: #1c72f2;

$light-red-highlight: rgba($vivid-red-100, 0.06);
