@import '~theme/variables/variables';

.custom-toast-container {
  background: $white;
  border: 1px solid $blue-20;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;

  .hot-toast-message {
    > :first-child {
      width: 100%;
    }
  }
}
