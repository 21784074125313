.modal-reset {
  padding: 0;

  .mat-dialog-container {
    padding: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15), 0 4px 30px rgba(0, 0, 0, 0.25);
  }
}

.reset-modal-border {
  .mat-dialog-container {
    border-radius: 0;
  }
}

.modal-base {
  font-family: $font-family-primary;
  font-weight: $base-font-weight;
  height: 100%;

  app-modal-section-header {
    display: block;
    margin-bottom: calculate-rem(10px);
  }
}
