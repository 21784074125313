@import 'variables/variables';
@import 'variables/bulk-edit-bar.variables';

.bulk-edit-bar-container {
  height: $app-bulk-edit-bar-height;
  width: 100%;
  background-color: $primary;
  padding: $bulk-edit-bar-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
